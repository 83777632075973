import React from 'react';
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Campaign from "../components/Campaign/Campaign"
import Breadcrumbs from '../components/AreaGuideDetails/Breadcrumbs/Campaign';
import {Helmet} from "react-helmet";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../scss/custom.scss";

const CampaignTemplate = (props) =>{
    
  const GQLPage = props.data?.glstrapi?.campaign;

    return (
        <React.Fragment>
            <div className="wrapper campaign-details-template">
            <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} />
            <Header />
                {/* <Breadcrumbs Pagename={GQLPage.Name} /> */}
                <Campaign {...GQLPage}/>
            <Footer />

            </div>
        </React.Fragment>
    )
}
export default CampaignTemplate;


export const pageQuery = graphql`
  query campaignQuery($id: ID!) {
      glstrapi {
        campaign(id: $id, publicationState: LIVE) {
            Banner_Content
            Banner_Sub_Title
            Meta_Description
            Meta_Title
            Name
            Show_Email_Address
            Show_Fullname
            Show_Property_Address
            Show_Telephone_Number
            Image_Upload
            Submit_Button_Text
            URL
            Background_Banner {
              url
              alternativeText
              url_sharp {
                childrenImageSharp {
                  fixed(width: 1430, quality: 90, cropFocus: CENTER, fit: COVER) {
                    srcWebp
                  }
                }
              }
            }
            Add_Select_Options {
              Label
              Add_Options {
                Option
              }
            }
            Terms_and_Conditions_Intro
            Add_New_Accordion {
              Content
              Title
            }
          }



      }
  }
`

